export const findLoanAmount = (propertyPrice, equity) => {
  equity = parseFloat(equity);
  propertyPrice = parseFloat(propertyPrice);
  console.log(propertyPrice, equity)
  const loanAmount = propertyPrice - equity
 return loanAmount
};

export const calculate = (loanAmount, monthlyIncome, rate, tenor) => {
  const PMT = (rate, nper, pv, fv, type) => {
    let pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (rate === 0) return -(pv + fv) / nper;

    pvif = Math.pow(1 + rate, nper);
    pmt = (-rate * (pv * pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + rate;
    return pmt;
  };

  loanAmount = parseFloat(loanAmount);
  tenor = parseInt(tenor);
  monthlyIncome = parseFloat(monthlyIncome.replace(/,/g, ""));
  rate = parseFloat(rate.replace(/,/g, ""));
  rate = rate / 1200;

  var monthlyRepayment = PMT(rate, tenor * 12, -1 * loanAmount);
  monthlyRepayment = monthlyRepayment + 0.001 * loanAmount;


  var dti = (monthlyRepayment / monthlyIncome) * 100;

  dti = dti.toFixed(2);

  monthlyRepayment = monthlyRepayment.toLocaleString();
  const result = [
    `₦${monthlyRepayment}`,
     `${dti}%`
  ]
  return result
};

export const shuffle = (array) => { 
  for (let i = array.length - 1; i > 0; i--) { 
    const j = Math.floor(Math.random() * (i + 1)); 
    [array[i], array[j]] = [array[j], array[i]]; 
  } 
  return array; 
}; 