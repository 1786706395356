import React, { useState } from "react";
import "./Accordion.css";

const Accordion = ({property}) => {
  const [caret, setCaret] = useState(
    <i className="fa-sharp fs-6 fa-solid fa-angle-down"></i>
  );
  const [caret1, setCaret1] = useState(
    <i className="fa-sharp fs-6 fa-solid fa-angle-down"></i>
  );

  const feature = property.propertyFeatures 

  function changeClass() {
    setCaret((previousState) => {
      if (previousState.props.className === "fa-sharp fs-6 fa-solid fa-angle-down") {
        return <i className="fa-sharp fs-6 fa-solid fa-angle-up"></i>;
      } else if (previousState === "fa-sharp fs-6 fa-solid fa-angle-up") {
        return <i className="fa-sharp fs-6 fa-solid fa-angle-down"></i>;
      } else {
        return <i className="fa-sharp fs-6 fa-solid fa-angle-down"></i>;
      }
    });
  }
  function changeClass2() {
    setCaret1((previousState) => {
      if (previousState.props.className === "fa-sharp fs-6 fa-solid fa-angle-down") {
        return <i className="fa-sharp fs-6 fa-solid fa-angle-up"></i>;
      } else if (previousState === "fa-sharp fs-6 fa-solid fa-angle-up") {
        return <i className="fa-sharp fs-6 fa-solid fa-angle-down"></i>;
      } else {
        return <i className="fa-sharp fs-6 fa-solid fa-angle-down"></i>;
      }
    });
  }

  return (
    <div className="accordion mb-4 mt-3" id="accordionPanelsStayOpenExample">
      <div className="accordion-item border-0">
        <h2
          className="accordion-header border-0"
          id="panelsStayOpen-headingOne"
        >
          <div className=" d-flex flex-row justify-content-between">
            <span className="ps-2 fw-bold fs-6 align-self-center">
              PROPERTY DESCRIPTION
            </span>
            <button
              className="acc"
              onClick={changeClass2}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseOne"
            >
              {caret1}
            </button>
          </div>
        </h2>
        <div
          id="panelsStayOpen-collapseOne"
          className="accordion-collapse collapse border-1"
          aria-labelledby="panelsStayOpen-headingOne"
        >
          <div className="accordion-body">
            {property.description}
          </div>
        </div>
      </div>
      <div className="accordion-item border-0">
        <h2
          className="accordion-header border-0"
          id="panelsStayOpen-headingTwo"
        >
          <div className=" d-flex flex-row justify-content-between">
            <span className="ps-2 fw-bold fs-6 align-self-center">
              PROPERTY FEATURE
            </span>
            <button
              className="acc"
              onClick={changeClass}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseTwo"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseTwo"
            >
              {caret}
            </button>
          </div>
        </h2>
        <div
          id="panelsStayOpen-collapseTwo"
          className="accordion-collapse collapse border-1"
          aria-labelledby="panelsStayOpen-headingTwo"
        >
          <div className="accordion-body features">
          <div className="row ps-3">
            {
                feature.map((feature,i) => 
                    <ul key={i} className="col-lg-3 ">
                    <li > {feature}</li> 
                    </ul>
               )
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
