import "./App.css";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import PropertyDetails from "./components/PropertyDetails";
import { SkeletonTheme } from "react-loading-skeleton";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import SwiperComponent from "./components/SwiperComponent";
import Rent from "./components/Rent";

function App() {
  return (
    <Router>
      <div className="App">
        <SkeletonTheme baseColor="#f0f0f0" highlightColor="#e6e4e1">
        <Navbar />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route  path="/property/:id">
            <PropertyDetails />
            </Route>
            <Route  path="/slide">
              <SwiperComponent />
            </Route>
            <Route  path="/rent">
              <Rent />
            </Route>
            
          </Switch>
          <Footer />
        </SkeletonTheme>
      </div>
    </Router>
  );
}

export default App;
