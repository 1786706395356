import {
    ALL_PROPERTY_FAIL,
    ALL_PROPERTY_SUCCESS,
    ALL_PROPERTY_REQUEST,
    PROPERTY_DETAILS_SUCCESS,
    PROPERTY_DETAILS_REQUEST,
    PROPERTY_DETAILS_FAIL,
    PROPERTY_SEARCH_SUCCESS,
    PROPERTY_SEARCH_REQUEST,
    PROPERTY_SEARCH_FAIL,
    PROPERTY_DEV_SEARCH_SUCCESS,
    PROPERTY_DEV_SEARCH_REQUEST,
    PROPERTY_DEV_SEARCH_FAIL,
    CLEAR_ERRORS
} from '../constants/property.constant';
import axios from 'axios'

export const getProperty = (page=1) => async (dispatch) => {
    try {
        dispatch({
            type: ALL_PROPERTY_REQUEST,
        })
        const config = {
            method : 'get',
            url : `https://radiusv2api.ivantage.africa/api/ivantage/properties?size=${40}&page=${page}`,
            headers : {'token' : 'adebam'}
        }

        const { data } = await axios(config)
        
        dispatch({
            type: ALL_PROPERTY_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({
            type : ALL_PROPERTY_FAIL,
            payload : "Could not fetch properties"
        })
    }
}

export const getPropertyDetails = (id) => async (dispatch) => {
    try {
        dispatch({
            type: PROPERTY_DETAILS_REQUEST
        })

        const email = localStorage.getItem("email")
       
        const config = {
            method : 'get',
            url : `https://radiusv2api.ivantage.africa/api/ivantage/property/${id}`,
            headers : {'email' : email}
        }
        const { data } = await axios(config)

        dispatch({
            type : PROPERTY_DETAILS_SUCCESS,
            payload : data
        })

        

    } catch (error) {
        dispatch({
            type : PROPERTY_DETAILS_FAIL,
            payload : "Could not fetch property"
        })
        
    }
}

export const propertySearch = ( searchdata, page=1) => async (dispatch) => {
    try{
        dispatch({
            type : PROPERTY_SEARCH_REQUEST
        })

        const config = {
            method: "get",
            url: `https://radiusv2api.ivantage.africa/api/ivantage/findproperties`,
            headers: { 'token' : 'adebam' },
            params: {
                propertyType: searchdata.propertyType,
                area: searchdata.area,
                status: searchdata.status,
                state: searchdata.state,
                numberOfBedrooms: searchdata.numberOfBedrooms,
                maxPrice: searchdata.maxPrice,
                minPrice: searchdata.minPrice,
                page: page,
                size :28
            },
          };

        const { data } = await axios(config)
        dispatch({
            type : PROPERTY_SEARCH_SUCCESS,
            payload : data
        })

    }
    catch (error) {
        dispatch({
            type : PROPERTY_SEARCH_FAIL,
            payload : "Requested Property Not Found"
        })        
    }
}

export const propertySearchByDev = (dev , page=1) => async (dispatch) => {
    try{
        dispatch({
            type : PROPERTY_DEV_SEARCH_REQUEST
        })
        const config = {
            method: "get",
            url: `https://radiusv2api.ivantage.africa/api/ivantage/property?size=${40}&page=${page}`,
            headers: { 'token' : 'adebam', 'companyId': `${dev.developer}`  },
          };

        const { data } = await axios(config)
        dispatch({
            type : PROPERTY_DEV_SEARCH_SUCCESS,
            payload : data
        })

    }
    catch (error) {
        dispatch({
            type : PROPERTY_DEV_SEARCH_FAIL,
            payload : "Could not fetch properties"
        })
        
    }
}

export const clearErrors =() => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}

