import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProperty, propertySearch, propertySearchByDev, clearErrors } from "../actions/property.actions";
import Pagination from "react-js-pagination";
import CardSkeleton from "./CardSkeleton";
import Card from "./Card";
import { Jumbotron } from "./Jumbotron";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { shuffle } from "../helpers/afr";

const Home = () => {

  let toastOptions = 
  {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  }

  const dispatch = useDispatch()
 
 
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState(null);
  const [devSearchData, setDevSearchData] = useState(null);
  const sendData = (data) => {
    setSearchData(data)
    setDevSearchData(null)
  }

  const devData = (devD) => {
    setDevSearchData(devD)
    setSearchData(null)
  }


  let { loading, property, totalData, error } = useSelector(
    (state) => searchData ? state.propertySearch : devSearchData ? state.propertyDevSearch : state.property
   
  );
if (property){
  property = shuffle(property)
}
 
  const handlePageClick = (event) => {
    setCurrentPage(event);
    
  };
  if (error) {
    toast.error( error, toastOptions )
    dispatch(clearErrors())
  }
  useEffect(() => {
   
    if(devSearchData){
      dispatch(propertySearchByDev(devSearchData, currentPage))

    }
    else if (searchData) {
      dispatch(propertySearch(searchData, currentPage))
    }
  
    else{
    dispatch(getProperty(currentPage));
    }
  }, [dispatch, currentPage, searchData, devSearchData ]);
  return (
    <>
    <Jumbotron devData={devData}  sendData={sendData}  />
      <div className="container">
        <div className="row">
          {loading && <CardSkeleton cards={16} />}
          <ToastContainer />


          {property && property.map((property) => <Card key={property.id} property={property} />)}
          {40 <= (totalData) && (
            <div className="d-flex justify-content-center mt-5 pagination" >
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={28}
                totalItemsCount={totalData}
                onChange={handlePageClick}
                nextPageText={"Next"}
                prevPageText={"Previous"}
                firstPageText={"First"}
                lastPageText={"Last"}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
