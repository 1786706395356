import {
  ALL_PROPERTY_FAIL,
  ALL_PROPERTY_SUCCESS,
  ALL_PROPERTY_REQUEST,
  PROPERTY_DETAILS_SUCCESS,
  PROPERTY_DETAILS_REQUEST,
  PROPERTY_DETAILS_FAIL,
  PROPERTY_SEARCH_SUCCESS,
  PROPERTY_SEARCH_REQUEST,
  PROPERTY_SEARCH_FAIL,
  PROPERTY_DEV_SEARCH_SUCCESS,
  PROPERTY_DEV_SEARCH_REQUEST,
  PROPERTY_DEV_SEARCH_FAIL,

  CLEAR_ERRORS,
} from "../constants/property.constant";

export const propertyReducers = (state = { property: [] }, action) => {
  switch (action.type) {
    case ALL_PROPERTY_REQUEST:
      return {
        loading: true,
        property: [],
      };
    case ALL_PROPERTY_SUCCESS:
      return {
        loading: false,
        property: action.payload.data.propertyData,
        totalData: action.payload.data.count,
      };
    case ALL_PROPERTY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const propertyDetailsReducers = (state = { property: {} }, action) => {
  switch (action.type) {
    case PROPERTY_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case PROPERTY_DETAILS_SUCCESS:
      return {
        loading: false,
        propertyDetails: action.payload.property,
      };
    case PROPERTY_DETAILS_FAIL:
        return {
            loading : false,
            error : action.payload
        }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const propertySearchReducers = (state = {property : [] }, action) => {
  switch (action.type) {
    case PROPERTY_SEARCH_REQUEST:
      return {
        loading : true,
        ...state
      }
    
    case PROPERTY_SEARCH_SUCCESS:
      return {
        loading : false,
        property : action.payload.data.propertyData,
        totalData: action.payload.data.count,
      }
    case PROPERTY_SEARCH_FAIL:
        return {
            loading : false,
            error : action.payload
        }
      case CLEAR_ERRORS:
          return {
            ...state,
            error: null,
          };
      default:
            return state;   
  }
}


export const propertyDevSearchReducers = (state = {property : [] }, action) => {
  switch (action.type) {
    case PROPERTY_DEV_SEARCH_REQUEST:
      return {
        loading : true,
        ...state
      }
    
    case PROPERTY_DEV_SEARCH_SUCCESS:
      return {
        loading : false,
        property : action.payload.data.propertyData,
        totalData: action.payload.data.count,
      }
    case PROPERTY_DEV_SEARCH_FAIL:
        return {
            loading : false,
            error : action.payload
        }
      case CLEAR_ERRORS:
          return {
            ...state,
            error: null,
          };
      default:
            return state;   
  }
}

