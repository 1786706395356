import React from "react";
import data from "../data/banks.json"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./Banks.css"
const Banks = () => {
  return (
    <>
    <p className="mt-5 fw-bold fs-6 ps-2" >BANK LOAN OFFERS</p>
      <div className="card mb-5 bank-card" >
        <div className="card-body d-flex"> 
        {data.map(data =>
         <div key={data.id} className=" card col-lg-2 m-2 bank-card" >
         <img className="bank-image" src={data.image} alt="Card image cap" />
         <div className="card-body">
           <h5 className="card-title banks-title">{data.rate}</h5>
           <p className="card-text banks-text">
             {data.name}
           </p>
           <Link to={{pathname: data.url}} target="_blank" className="btn btn-primary banks-btn align-self-end mb-1">
             <font className="banks-link">
                 VISIT
             </font>
           </Link>
           
           <Link to={{pathname: data.rsaUrl}} target="_blank" className="btn btn-primary banks-btn align-self-end ">
             <font className="banks-link">
                 RSA 
             </font>
           </Link>
         </div>
       </div> )}
         
        </div>
      </div>
    </>
  );
};

export default Banks;


