import React from "react";
import "./Card.css";
import {Link} from 'react-router-dom'

const Card = ({ property }) => {

  return (
    <div key={property.id} className="col-sm-12 col-md-6 col-lg-3 p-4 ">
      <Link to={{pathname :`/property/${property.id}`, state : {from : "/"}}} className="card_link" >
      <div className="card border-0 p-1 property">
        <img
          src={property.images[0]}
          className="card-img-top"
          alt="..."
        />
        <div className="top-right">{property.status}</div>
        <div className="top-right-two">{property.propertyType}</div>

        <div className="card-body ps-2 pe-2">
          <h5 className="card-title" style={{fontSize: 17}}>
          {property.projectName}

          </h5>
          <p className="card-text truncate-overflow" style={{fontSize: 13}}>
            {property.description}
          </p>
        </div>
        <div className="card-body d-flex justify-content-between flex-row p-0 ps-2 m-0">
          <div className="fw-bold fs-6 p-0">
          ₦{parseFloat((property.price)).toLocaleString()}
          </div>
          <div>
            <div>
            <i className="fa-solid fa-bed pe-1"></i>
         {property.numberOfBedrooms} 
    <i className="fa-solid fa-bath ps-2 pe-1"></i>
     {property.numberOfWashrooms}
            </div>            
          </div>
        </div>
      </div>
      </Link>

    </div>
  );
}

export default Card;
