import {createStore, combineReducers, applyMiddleware} from "redux"
import thunk from "redux-thunk"
import {composeWithDevTools} from "redux-devtools-extension"
import {propertyDetailsReducers, propertyReducers, propertySearchReducers, propertyDevSearchReducers} from './reducers/property.reducers'

const reducer = combineReducers({
    property : propertyReducers,
    propertyDetails : propertyDetailsReducers,
    propertySearch : propertySearchReducers,
    propertyDevSearch : propertyDevSearchReducers

})

let initialState = {}
const middleware = [thunk]
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store




