import React, { useEffect, useRef, useState } from "react";
import "./PropertyDetails.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { getPropertyDetails } from "../actions/property.actions";
import SwiperComponent from "./SwiperComponent";
import Banks from "./Banks";
import Accordion from "./Accordion";
import Description from "./Description";
import Recommended from "./Recommended";
import { findLoanAmount, calculate } from "../helpers/afr";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import PropertyDetailsSkeleton from "./PropertyDetailsSkeleton";

const PropertyDetails = () => {
  const [verifyModal, setVerifyModal] = useState(false);
  let monthlyIncome = useRef();
  let tenor = useRef();
  let interest = useRef();
  let equity = useRef();
  let propertyPrice = useRef();
  let fullName = useRef();
  let phoneNumber = useRef();
  let email = useRef();
  let date = useRef();
  let comment = useRef();

  let vFullName = useRef();
  let vPhoneNumber = useRef();
  let vEmail = useRef();

  let otp = useRef();
  let toastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    const config = {
      method: "post",
      url: "https://radiusv2api.ivantage.africa/api/external/ivantage/email",
      data: {
        name: fullName.current.value,
        phone: phoneNumber.current.value,
        email: email.current.value,
        date: date.current.value,
        comment: comment.current.value,
      },
    };
    try {
      let { data } = await axios(config);
      if (data.success === true) {
        toast.success(data.message, toastOptions);
      } else if (data.success === false) {
        toast.error(data.message, toastOptions);
      }
    } catch (error) {
      toast.error(error.message, toastOptions);
    }
  };

  const registerVisitor = async (e) => {
    e.preventDefault();

    const config = {
      method: "post",
      url: "https://radiusv2api.ivantage.africa/api/ivantage/register-visitor",
      data: {
        name: vFullName.current.value,
        phoneNumber: vPhoneNumber.current.value,
        email: vEmail.current.value,
      },
    };
    try {
      let { data } = await axios(config);
      localStorage.setItem("email", vEmail.current.value);
      toast.success(data.data?.message, toastOptions);
        toast.success(data.message, toastOptions);
      console.log(data)
      if (data.message === "Email has been verified"){
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
     else if (data.success === true || data.status === true) {
        setVerifyModal(true);
        toast.success(data.data?.message, toastOptions);
        toast.success(data.message, toastOptions);
        localStorage.setItem("email", vEmail.current.value);
      } else if (data.success === false || data.status === false) {
        toast.error(data.message, toastOptions);
      }
    } catch (error) {
      toast.error(error.message, toastOptions);
    }
  };

  const verifyEmail = async (e) => {
    e.preventDefault();
    const config = {
      method: "post",
      url: "https://radiusv2api.ivantage.africa/api/ivantage/verify-email",
      data: {
        otp: otp.current.value,
        email: localStorage.getItem("email"),
      },
    };
    try {
      let { data } = await axios(config);
      if (data.status === true) {
        
        toast.success(data.message, toastOptions);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (data.success === false) {
        toast.error(data.message, toastOptions);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message, toastOptions);
    }
  };

  const [loanAmount, setLoanAmount] = useState(0);
  let [repayment, setRepayment] = useState("");
  let [debt, setDebt] = useState("");
  const [nmi, setNmi] = useState("");
  const [pp, setPp] = useState("");
  const [eq, setEq] = useState("");

  const handlenmi = (e) => {
    let value = e.target.value;
    value = value.replaceAll(",", "");
    value = parseInt(value);
    if (isNaN(value)) {
      value = "";
    }
    value = value.toLocaleString("en-gb");

    setNmi(value);
  };

  const handlepp = (e) => {
    let value = e.target.value;
    value = value.replaceAll(",", "");
    value = parseInt(value);
    if (isNaN(value)) {
      value = "";
    }
    value = value.toLocaleString("en-gb");

    setPp(value);
  };

  const findLoan = (e) => {
    let value = e.target.value;
    value = value.replaceAll(",", "");
    value = parseInt(value);
    if (isNaN(value)) {
      value = "";
    }
    value = value.toLocaleString("en-gb");

    setEq(value);

    propertyPrice = propertyPrice.current.value.replaceAll(",", "");
    equity = equity.current.value.replaceAll(",", "");
    const loan = findLoanAmount(propertyPrice, equity);
    setLoanAmount(loan);
  };

  const dispatch = useDispatch();
  const { loading, propertyDetails, error } = useSelector(
    (state) => state.propertyDetails
  );
  const { id } = useParams();
  const phone = propertyDetails?.phoneNumber;

  const getAffordability = (e) => {
    e.preventDefault();

    const data = {
      monthlyIncome: monthlyIncome.current.value.replaceAll(",", ""),
      tenor: tenor.current.value,
      interest: interest.current.value,
      equity: equity.current.value.replaceAll(",", ""),
      propertyPrice: propertyPrice.current.value.replaceAll(",", ""),
    };

    const [monthlyRepayment, dti] = calculate(
      loanAmount,
      data.monthlyIncome,
      data.interest,
      data.tenor
    );
    setRepayment(monthlyRepayment);
    setDebt(dti);
  };

  useEffect(() => {
    dispatch(getPropertyDetails(id));
  }, [dispatch, id]);

  return (
    <>
      {loading && <PropertyDetailsSkeleton />}

      {propertyDetails && (
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mt-4">
              <div className="card">
                <div className="card-body">
                  <i className="fa-sharp fa-solid fa-angle-left"></i> &nbsp;
                  <Link to="/" className="card-link">
                    back to home page
                  </Link>
                  <ToastContainer />
                </div>
              </div>
              <div className="d-flex justify-content-between price">
                <div className="pt-4 ps-4">
                  <p className="mb-0 fw-bold">{propertyDetails.propertyCode}</p>
                  <h3>{propertyDetails.projectName}</h3>
                  <p>
                    <i className="fa-solid fa-location-pin text-danger"></i>{" "}
                    &nbsp;
                    {propertyDetails.address}, {propertyDetails.state}
                    &nbsp; &nbsp;
                    {propertyDetails.verificatioConducted &&
                      propertyDetails.verificatioConducted.map(
                        (verifications, i) => (
                          <li
                            key={i}
                            style={{ display: "inline" }}
                            className="text-success fw-bold"
                          >
                            {verifications} Conducted
                          </li>
                        )
                      )}
                  </p>
                </div>
                <div className="pt-4 ps-4 m-0">
                  <span className="p-0 m-0"> Property Price</span>
                  <p className="mb-0 fw-bold fs-2 pb-0 mb-0">
                    ₦{parseFloat(propertyDetails.price).toLocaleString()}
                  </p>
                </div>
              </div>

              <div id="propImage" className="pt-2 mb-4">
                <SwiperComponent property={propertyDetails} />
              </div>

              <span className="ps-2 fw-bold fs">GENERAL DESCRIPTION</span>
              <div className="card border-0 description mt-3">
                <div className="card-body ">
                  <div className="d-flex flex-row justify-content-around">
                    <Description property={propertyDetails} />
                  </div>
                </div>
              </div>
              <Accordion property={propertyDetails} />

              <Banks />

              <span className="ps-2 fw-bold fs">PROPERTY LOCATION</span>
              <div className="google-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d31705.755620116488!2d3.223490964135758!3d6.619637639848315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sng!4v1685277879011!5m2!1sen!2sng"
                  style={{ border: 0 }}
                  title="Google maps"
                ></iframe>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card shadow border-0 mt-3">
                <div className="card-body">
                  <h5>Affordability Calculator</h5>

                  <div className="row">
                    <div className="mt-1d-flex">
                      <form onSubmit={getAffordability}>
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="form-group col-md-12">
                              <label className="label">
                                Net Monthly Income
                              </label>
                              <input
                                type="text"
                                name="name"
                                className="form-control number-separator"
                                id="monthlyIncome"
                                placeholder=""
                                ref={monthlyIncome}
                                value={nmi}
                                onChange={handlenmi}
                                // required
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group col-md-12 mt-3">
                              <label className="label">Property Price</label>
                              <input
                                type="text"
                                className="form-control number-separator"
                                id="propertyPrice"
                                placeholder=""
                                ref={propertyPrice}
                                value={pp}
                                onChange={handlepp}
                                // required
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-12 mt-3">
                              <label className="label">
                                Equity Contribution
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                min="0"
                                max="100"
                                id="equity"
                                placeholder=""
                                ref={equity}
                                value={eq}
                                onChange={findLoan}
                                // required
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group col-md-12 mt-3">
                              <label className="label">Loan Amount</label>
                              <input
                                type="text"
                                className="form-control"
                                id="loanAmount"
                                disabled
                                value={loanAmount.toLocaleString()}
                                // required
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group col-md-12 mt-3">
                              <label className="label">Tenor</label>
                              <span id="tenor"></span>
                              <input
                                type="number"
                                min="1"
                                className="form-control"
                                id="tenure"
                                ref={tenor}
                                // required
                              />
                            </div>
                          </div>

                          <div className="form-group col-md-12 mt-3">
                            <label className="label">Interest Per Annum</label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder=""
                              ref={interest}
                              // required
                            />
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="mt-4 mb-4 btn btn-success"
                            >
                              <font>calculate</font>
                            </button>
                          </div>

                          <div className="row result">
                            <div className="col-lg-6">
                              <span>Monthly Repayment:</span>
                              <div className="fw-bold">{repayment}</div>
                            </div>
                            <div className="col-lg-6">
                              <span> Debt to Income Ratio: </span>
                              <div className=" fw-bold" id="dtiResult">
                                {debt}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" card shadow border-0 mt-3 mt-3 rounded-3 bg-secondary text-white ">
                <div className="card-body">
                  <h6 className="card-title">Developer / Agent Details</h6>
                  <div className=" d-flex  justify-content-center bg-light">
                    {propertyDetails?.showDetails && (
                      <div className=" card-text m-0 text-dark ">
                        <div className="mb-3">
                          <p className="m-0 text-center fw-bold">
                            Contact Person:
                          </p>
                          <p className="text-center">
                            {propertyDetails.contactPerson}
                          </p>
                        </div>
                        <div className="mb-3">
                          <p className="m-0 text-center fw-bold">
                            Phone Number:
                          </p>
                          {phone &&
                            phone.map((phone, i) => (
                              <div key={i}>
                                <p className="card-text m-0 text-center">
                                  {phone}
                                </p>
                              </div>
                            ))}
                        </div>
                        <div className="mb-3">
                          <p className="text-center fw-bold m-0">
                            Email Address
                          </p>
                          <p className="text-center m-0">
                            {propertyDetails.developer == "Ivantage"
                              ? "aderemi.onifade@ivantage.africa"
                              : propertyDetails.email}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="">
                    <button
                      type="button"
                      className="btn btn-primary m-3"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      schedule a tour
                    </button>
                    {!propertyDetails?.showDetails && (
                      <button
                        type="button"
                        className="btn btn-success m-3"
                        data-bs-toggle="modal"
                        data-bs-target="#visitorRegister"
                      >
                        view contact details
                      </button>
                    )}
                  </div>

                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Schedule Visit / Property Interest
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <form onSubmit={sendEmail}>
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Full Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputEmail1"
                                ref={fullName}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Phone Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputEmail1"
                                ref={phoneNumber}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Email address
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                ref={email}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Date</label>
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputPassword1"
                                ref={date}
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputComment"
                                className="form-label"
                              >
                                Comment
                              </label>
                              <textarea
                                type="password"
                                className="form-control"
                                id="exampleInputPassword1"
                                ref={comment}
                              />
                            </div>
                            <div className="d-flex ">
                              <button type="submit" className="btn btn-success">
                                send
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="modal fade"
                    id="visitorRegister"
                    tabIndex="-1"
                    aria-labelledby=""
                    aria-hidden="true"
                  >
                    {verifyModal ? (
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Verify OTP
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <form onSubmit={verifyEmail}>
                              <div className="mb-3">
                                <label
                                  htmlFor=""
                                  className="form-label"
                                >
                                  One Time Password (OTP)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  ref={otp}
                                  required
                                />
                              </div>

                              <div className="d-flex ">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  send
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Register
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <form onSubmit={registerVisitor}>
                              <div className="mb-3">
                                <label
                                  htmlFor=""
                                  className="form-label"
                                >
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  ref={vFullName}
                                  required
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor=""
                                  className="form-label"
                                >
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  ref={vPhoneNumber}
                                  required
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor=""
                                  className="form-label"
                                >
                                  Email address
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id=""
                                  ref={vEmail}
                                  required
                                />
                              </div>

                              <div className="d-flex ">
                                <button className="btn btn-success">
                                  send
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="card shadow border-0 mt-3 mt-3 rounded-3 text-white disclaimer">
                <div className="card-body">
                  <h6 className="card-title text-dark fw-bold">Disclaimer</h6>
                  <div className=" d-flex  text-dark ">
                    <article>
                      The property details provided are an advertisement by
                      iVantage Technology Limited. We do not guarantee the
                      accuracy or completeness of this advertisement or any
                      associated information. We do not control the content.
                      This listing does not constitute property particulars. The
                      information is managed by the property agent. Ivantage
                      Technology Limited is not liable for the actions of any
                      agent or property owner/landlord on or off this website.
                    </article>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      )}
      <div className="container">
        <span className="fw-bold fs">SIMILAR PROPERTIES</span>

        <Recommended property={propertyDetails} />
      </div>
    </>
  );
};

export default PropertyDetails;
