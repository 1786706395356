export const ALL_PROPERTY_SUCCESS = 'ALL PROPERTY SUCCESS'
export const ALL_PROPERTY_REQUEST = 'ALL PROPERTY REQUEST'
export const ALL_PROPERTY_FAIL = 'ALL PROPERTY FAIL'
export const PROPERTY_DETAILS_SUCCESS = 'PROPERTY DETAILS SUCCESS'
export const PROPERTY_DETAILS_REQUEST = 'PROPERTY DETAILS REQUEST'
export const PROPERTY_DETAILS_FAIL = 'PROPERTY DETAILS FAIL'
export const PROPERTY_SEARCH_SUCCESS = 'PROPERTY SEARCH SUCCESS'
export const PROPERTY_SEARCH_REQUEST = 'PROPERTY SEARCH REQUEST'
export const PROPERTY_SEARCH_FAIL = 'PROPERTY SEARCH FAIL'
export const PROPERTY_DEV_SEARCH_SUCCESS = 'PROPERTY DEV SEARCH SUCCESS'
export const PROPERTY_DEV_SEARCH_REQUEST = 'PROPERTY DEV SEARCH REQUEST'
export const PROPERTY_DEV_SEARCH_FAIL = 'PROPERTY DEV SEARCH FAIL'
export const CLEAR_ERRORS = 'CLEAR ERRORS'