import React from 'react'
import "./Footer.css"
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Footer = () => {
  return (

<>
<div  className="container-fluid mt-4 footer-full">
    <footer className="py-5 container">
      <div className="row">
        <div className="col-lg-4">
          <h5>About</h5>
          <ul className="nav flex-column">
            <li>
                We offer a shortened and hitch-free customer journey towards 
                property identification, mortgage processing, mortgage approval 
                and subsequent disbursement and monitoring.
            </li>
            </ul>
        </div> 
        <div className="col-lg-4">
          <h5>Useful Links</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-muted">Home</Link></li>
            <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-muted">Property Listing</Link></li>
            <li className="nav-item mb-2"><Link to={{pathname : "https://ivantage.africa/assets/pdf/iVantagePrivacyPolicy.pdf"}} target="_blank" className="nav-link p-0 text-muted">Privacy Policy</Link></li>
            <li className="nav-item mb-2"><Link to={{pathname : "https://ivantage.africa/faq.html"}} target="_blank" className="nav-link p-0 text-muted">FAQ</Link></li>

          </ul>
        </div>
  
        <div className="col-lg-4 ">
          <h5>Contact Us</h5>

         <div>

          <p style={{margin : 0}}>  <i className="fa-solid fa-phone bg-success phone-icon" ></i>  <font id="phone"> 0906 397 6238 </font></p>
          <p style={{paddingTop : 15}}>
           ivantage Limited Calls may be recorded for quality and training purposes.
          </p>
         </div>
          <h5 className='pt-2'>Download Our Mobile app</h5>
              <div className='pt-2'>
              <img src='https://www.imperialmortgagebank.com/assets/img/androidstore.png'  className='img-fluid download' alt='playstore' />
              </div>
              <div className='pt-2'>
              <img src=' https://www.imperialmortgagebank.com/assets/img/applestore.png'  className='img-fluid download' alt='apple store' />
              </div>
  
        </div>
      </div>
  
      <div className="d-flex pt-2 mt-4 border-top border-2">
            <div className="flex-grow-1 p-2">
                <p> 2023 &copy; Ivantage Technolgy Limited All rights reserved.</p>
            </div>
            <div className="p-2 flex-shrink-1 fs-3"> 
                
            <Link to={{pathname : "https://www.instagram.com/ivantagetech/"}} target="_blank">
            <i className="fa-brands fa-square-instagram pe-1"></i>
            </Link>
            <Link to={{pathname: "https://web.facebook.com/profile.php?id=100088414636870&_rdc=1&_rdr"}} target="_blank">
            <i className="fa-brands fa-square-facebook pe-1"></i>
            </Link>
            <Link to={{pathname : "https://www.linkedin.com/company/ivantage-technology-services-limited/"}} target="_blank">
               <i className="fa-brands fa-linkedin pe-1"></i> 
            </Link>
                
                
                </div>
     
      </div>
    </footer>
  </div>
</>

  )
}

export default Footer