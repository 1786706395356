import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./Swiper.css";
import { FreeMode, Navigation, Thumbs } from "swiper";

const SwiperComponent = ({property}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const images = property.images

  return (
    <>
    <div>
    </div>
    <Swiper
      style={{
        "--swiper-navigation-color": "#fff",
        "--swiper-pagination-color": "#fff",
      }}
      loop={true}
      spaceBetween={10}
      navigation={true}
      thumbs={{ swiper: thumbsSwiper }}
      modules={[FreeMode, Navigation, Thumbs]}
      className="mySwiper2">
      {
        images.map((images,i) => 
        <SwiperSlide key={i}>
        <img src={images} alt="{image}" />
      </SwiperSlide>
        )
      }
    </Swiper>
    <Swiper
      onSwiper={setThumbsSwiper}
      loop={true}
      spaceBetween={10}
      slidesPerView={4}
      freeMode={true}
      watchSlidesProgress={true}
      modules={[FreeMode, Navigation, Thumbs]}
      className="mySwiper"
    >
  {
        images.map((images,i) => 
        <SwiperSlide key={i}>
        <img src={images} alt="{image}" />
      </SwiperSlide>
        )
      }
    </Swiper>

     
  </>
  )
}

export default SwiperComponent