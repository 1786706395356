import React, { useEffect, useState } from "react";
import axios from 'axios'
import "./Jumbotron.css";

export const Jumbotron = (props) => {
  const [state, setState] = useState("");
  const [area, setArea] = useState("");
  const [numberOfBedrooms, setNumberOfBedrooms] = useState("");
  const [status, setStatus] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [developer, setDeveloper] = useState("");
  const [devList, setDevList] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchData = {
      state,
      area,
      numberOfBedrooms,
      status,
      propertyType,
      minPrice : minPrice.replaceAll(",", ""),
      maxPrice : maxPrice.replaceAll(",", ""),
    };
    props.sendData(searchData);
  };

  const handleSetMax = (e) => {
   let value = e.target.value
   value = value.replaceAll(",", "")
   value = parseInt(value)
   if (isNaN(value)) {
    value = ""
   }
  value = value.toLocaleString('en-gb')
  setMaxPrice(value)
  }

  const handleSetMin = (e) => {
    let value = e.target.value
    value = value.replaceAll(",", "")
    value = parseInt(value)
    if (isNaN(value)) {
     value = ""
    }
   value = value.toLocaleString('en-gb')
   setMinPrice(value)
   }
 
  const handleDevSubmit = (e) => {
    e.preventDefault();
    const devData ={developer} ;

    props.devData(devData);
  };
  const getDevelopers = async () => {
    const config = {
      method: "get",
      url: "https://radiusv2api.ivantage.africa/api/ivantage/company",
      headers: { token: "adebam" },
  }
  let { data } = await axios(config);
  return data.data.company
}

  useEffect(() => {
    getDevelopers().then((res) => {
      setDevList(res)
    })
  }, [setDevList])

  return (
    <div className="container-fluid">
      <div className="jumbotron ">
        <div className="overlay">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="filter card ">
              <div className="card-body m-1 p-0">
                <form onSubmit={handleSubmit} className="form-floating p-0 m-0">
                  <div className="row ">
                    <div className="form-floating col-lg-1">
                    <select
                        className="form-select form-select-sm input-border"
                        aria-label=".form-select-sm example"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        id="floatingSelect"
                      >
                        <option value="">All</option>
                        <option value="Lagos">Lagos</option>
                        <option value="Abuja">Abuja</option>
                        </select>
                     
                      <label htmlFor="floatingtext">
                        <b>State</b>
                      </label>
                    </div>
                    <div className="form-floating col-lg-1">
                      <input
                        type="text"
                        className=" form-control input-border"
                        id="floatingtext"
                        value={area}
                        onChange={(e) => setArea(e.target.value)}
                        placeholder="text"
                      />
                      <label htmlFor="floatingtext">
                        <b>Area</b>
                      </label>
                    </div>
                    
                    <div className="form-floating col-lg-1">
                    <select
                        className="form-select form-select-sm input-border"
                        aria-label=".form-select-sm example"
                        value={numberOfBedrooms}
                        onChange={(e) => setNumberOfBedrooms(e.target.value)}
                        id="floatingSelect"
                      >
                        <option value="">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        </select>
                        <label htmlFor="floatingtext">
                        <b>Beds</b>
                      </label>
                    </div>
                    {/* <div className="form-floating col-lg-1">
                      <input
                        type="text"
                        className=" form-control input-border"
                        id="floatingtext"
                        value={numberOfBedrooms}
                        onChange={(e) => setNumberOfBedrooms(e.target.value)}
                        placeholder="text"
                      />
                      <label htmlFor="floatingtext">
                        <b>Beds</b>
                      </label>
                    </div> */}
                    <div className="form-floating col-lg-2">
                    <select
                        className="form-select form-select-sm input-border"
                        aria-label=".form-select-sm example"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        id="floatingSelect"
                      >
                        <option value="">All</option>
                        <option value="off_plan">Off Plan</option>
                        <option value="completed">Completed</option>
                        <option value="under_construction">Under Construction</option>
                        </select>
                     
                      <label htmlFor="floatingtext">
                        <b>Status</b>
                      </label>
                    </div>
                    <div className="form-floating col-lg-2">
                    <select
                        className="form-select form-select-sm input-border"
                        aria-label=".form-select-sm example"
                        value={propertyType}
                        onChange={(e) => setPropertyType(e.target.value)}
                        id="floatingSelect"
                      >
                        <option value="">All</option>
                        <option value="apartments">Apartments</option>
                        <option value="bare_land">Bare Land</option>
                        <option value="bungalow">Bungalow</option>
                        <option value="duplex">Duplex</option>
                        <option value="fully_detached">Fully Detached</option>
                        <option value="maisonette">Maisonette</option>
                        <option value="penthouse">Penthouse</option>
                        <option value="terrace">Terrace</option>
                        <option value="semi_detached">Semi Detached</option>
                        <option value="shell">Shell</option>
                        <option value="site_and_services">Site and services</option>
                        <option value="studio_apartment">Studio Apartment</option>
                      </select>
                   
                      <label htmlFor="floatingtext">
                        <b>Property Type</b>
                      </label>
                    </div>
                    <div className="form-floating col-lg-2">
                      <input
                        type="text"
                        className=" form-control input-border"
                        value={minPrice}
                        onChange= {handleSetMin}
                        id="floatingtext"
                        placeholder="text"
                      />
                      <label htmlFor="floatingtext">
                        <b>Min. Price (₦)</b>
                      </label>
                    </div>
                    <div className="form-floating col-lg-2">
                      <input
                        type="text"
                        className=" form-control input-border"
                        value={maxPrice}
                        onChange={handleSetMax}
                        id="floatingtext"
                        placeholder="text"
                      />
                      <label htmlFor="floatingPassword">
                        <b>Max. Price (₦)</b>
                      </label>
                    </div>
                    <div className="form-floating col-lg-1 d-flex">
                      <button type="submit" className="btn btn-success w-100">
                        <i className="fa-solid fa-magnifying-glass fs-5 pe-2 ps-2"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-5">
          <div className="separator col-lg-7 pb-4">Find Properties By Developer</div>

            <div className="card filters col-lg-7  m-0">
              <div className="card-body m-1 p-0">
                
                <form onSubmit={handleDevSubmit} className="form-floating p-0 m-0">
                  <div className="row">
                    <div className="form-floating col-lg-10">
                      <select
                        className="form-select form-select-sm input-border"
                        aria-label=".form-select-sm example"
                        value={developer}
                        id="floatingSelect"
                        onChange={(e) => setDeveloper(e.target.value)}
                      >
                        <option value={""}>Pick a Developer</option>

                        {devList && devList.map((devList) => 
                          <option value={devList.id} key={devList.id}>{devList.name}</option>
                        )}
                      </select>
                      <label htmlFor="floatingPassword">
                        <b>Name of Developer</b>
                      </label>
                    </div>
                    <div className="form-floating col-lg-2 d-flex">
                      <button type="submit" className="btn btn-success w-100">
                        <i className="fa-solid fa-magnifying-glass fs-5 pe-2 ps-2"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <center>
          <h1 className="first-font ">Find your dream home!</h1>
          <p className="second-font">
            Discover your ideal space. Benefit from versatile payment choices.{" "}
            <br /> Seamlessly handle your amenities while on the move.
          </p>
          </center>
         
        </div>
        </div>
     
      </div>
    </div>
  );
};



