import React from "react";
import "./Description.css"

const Description = ({property}) => {
  return (
    <>
    <div className="row desc ">


    {property.numberOfBedrooms && 
        <div className=" col-lg-3">
    <div className="d-flex flex-column align-items-center">
        <i className="fa-solid fa-bed"></i>
        <div>
        {property.numberOfBedrooms} Bedrooms

        </div>
      </div>
    </div> 
      }

    {property.numberOfWashrooms && 
        <div className=" col-lg-3">
    <div className="d-flex flex-column align-items-center">
    <i className="fa-solid fa-bath"></i>
        <div>
        {property.numberOfWashrooms} Washrooms

        </div>
      </div>
    </div> 
      }

    {property.completionDate && 
        <div className=" col-lg-3">
    <div className="d-flex flex-column align-items-center">
    <i className="fa-sharp fa-solid fa-calendar"></i>
        <div>
        {property.completionDate}

        </div>
      </div>
    </div> 
      }

    {property.propertyType && 
        <div className=" col-lg-3">
    <div className="d-flex flex-column align-items-center">
    <i className="fa-solid fa-house-flag"></i> 
        <div>
        {property.propertyType}

        </div>
      </div>
    </div> 
      }

    {property.status && 
        <div className=" col-lg-3">
    <div className="d-flex flex-column align-items-center">
    <i className="fa-solid fa-person-digging"></i>
        <div>
        {property.status}

        </div>
      </div>
    </div> 
      }

    {property.propertySize && 
        <div className=" col-lg-3">
    <div className="d-flex flex-column align-items-center">
    <i className="fa-solid fa-ruler-combined"></i>
        <div>
        {property.propertySize} Sq. M

        </div>
      </div>
    </div> 
      }

    {property.availableUnits && 
        <div className=" col-lg-3">
    <div className="d-flex flex-column align-items-center">
    <i className="fa-solid fa-building"></i> 
        <div>
        {property.availableUnits} Units

        </div>
      </div>
    </div> 
      }

    {property.projectStatus && 
        <div className=" col-lg-3">
    <div className="d-flex flex-column align-items-center">
       <i className="fa-solid fa-shield"></i>
        <div>
        {property.projectStatus}

        </div>
      </div>
    </div> 
      }
  
  </div>


    </>
  );
};

export default Description;
