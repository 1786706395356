import React from 'react'
import "./Navbar.css"
import { Link, NavLink } from 'react-router-dom/cjs/react-router-dom.min'

const Navbar = () => {
  return (
    <>
    <div className="container-fluid">
    <nav className="navbar navbar-expand-lg navbar-light bg-light  ">
    <div className="container">
      
        <Link to="/" className="navbar-brand">
            <img src="https://www.ivantage.africa/assets/img/logo/iVantage_logo.png" alt="i-Vantage" width={150} className="d-inline-block align-text-top"  />
          </Link>
       
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item buy">
            <NavLink to="/" className="nav-link  " aria-current="page" > <i className="fa-solid fa-money-bill-1"></i> BUY</NavLink>
          </li>
          <li className="nav-item buy">
            <NavLink className="nav-link active"  to="/rent" > <i  className="fa-sharp fa-solid fa-bell"></i> RENT</NavLink>
          </li>
        </ul>
        <div className='d-flex flex-row justify-content-between'>
       <div className='mr-4'>
        <Link to={{pathname: "https://www.ivantage.africa"}} target="_blank">
          <input type="button" style={{fontSize: 13, fontWeight: 700}} className="btn btn-success" value="VISIT IVANTAGE" />
        </Link>
        </div>
        <div className='mx-3'>
        <Link to={{pathname: "https://www.realtor.ivantage.africa"}} target="_blank" className="">
          <input type="button" style={{fontSize: 13, fontWeight: 700, }} className="btn btn-warning" value="REALTOR'S PORTAL" />
        </Link>
        </div>
        </div>
      </div>
    </div>
  </nav>
    </div>
     
    </>
  )
}

export default Navbar