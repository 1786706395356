import React from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'



const PropertyDetails = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mt-4">
                <Skeleton />
            <div className="pt-4 ps-4">
              <p className="mb-0"> <Skeleton /> </p>
              <h3><Skeleton /></h3>
              <p>
               <Skeleton />
              </p>
            </div>
            <div id="propImage" className="pt-5 mb-4">
                <Skeleton height={600} />
            </div>
            <span id="desc" className="ps-2">
                <Skeleton />
            </span>
              <Skeleton  height={100}/>
               <Skeleton count={5} style={{marginBottom : "10px"}} />
           
          </div>

          {/* side nav */}
          
          <div className="col-lg-4">
            <div className=" card shadow border-0 mt-3 mt-3 rounded-3 ">
              <div className="card-body">
                <h5 className="card-title">
                <Skeleton />
                </h5>
                <p className="card-text m-0">
                    <Skeleton count={4} />
                </p>
             
              </div>
            </div>
            <div className="card shadow border-0 mt-3 mt-3 rounded-3">
              <div className="card-body">
              <h5 className="card-title">
                <Skeleton />
                </h5>
               
                    <form role="form">
                      <div className="row">
                        <div className="form-group col-md-12">
                         <Skeleton />
                         <Skeleton height={35} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-12">
                         <Skeleton />
                         <Skeleton height={35} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-12">
                         <Skeleton />
                         <Skeleton height={35} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-12">
                         <Skeleton />
                         <Skeleton height={35} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-12">
                         <Skeleton />
                         <Skeleton height={35} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-12">
                         <Skeleton />
                         <Skeleton height={35} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-12">
                         <Skeleton />
                         <Skeleton height={35} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-12">
                         <Skeleton />
                         <Skeleton height={35} />
                        </div>
                      </div>
                    
                    </form>
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyDetails;
