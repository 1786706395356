import React, { useEffect, useState } from "react";
import axios from "axios";
import CardSkeleton from "./CardSkeleton";
import Card from "./Card";
import "./Recommended.css"

const Recommended = ({ property }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [recommended, setRecommended] = useState([]);


  const getData = async (param) => {
    const config = {
      method: "get",
      url: "https://radiusv2api.ivantage.africa/api/ivantage/findproperties",
      headers: { token: "adebam" },
      params: {
        propertyType: param.propertyType,
        status: param.status,
        state: param.state,
        numberOfBedrooms: param.numberOfBedrooms,
        size: 4,
      },
    };
    let { data } = await axios(config);
    return data
  };

  useEffect(() => {
    if (property){
    getData(property).then((res) => {
        setRecommended(res.data.propertyData)
        setIsLoading(false)
    });
  }
    
  }, [property]);
  return (
    <>
      <div>
        <div className="container-fluid">
          <div className=" recommended d-flex flex-row ">
            {isLoading && <CardSkeleton cards={4} />}
            <div className="row">

{recommended && recommended.map((recommended) => <Card key={recommended.id} property={recommended} className="reduce" />)}



          </div>
        </div>
        </div>

      </div>
    </>
  );
};

export default Recommended;
