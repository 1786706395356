import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CardSkeleton = ({cards}) => {
  return (
    Array(cards).fill(0).map((item, i) => (
      <div className='col-sm-12 col-md-6 col-lg-3 mt-5' key={i}>
      <div className=" card border-0 rounded p-1 ">
        <Skeleton height={200} />
        </div>
        <h5 className="card-title p-1">
        <Skeleton width={200} style={{marginBottom : "5px"}} />
        </h5>
        <p className="card-text p-1">
        <Skeleton count={4}  />
          </p>
  </div>
    ))

  )
}

export default CardSkeleton